import React, { useEffect } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import ImageIcon from "@mui/icons-material/Image";
import MusicVideoIcon from "@mui/icons-material/MusicVideo";
import VideocamIcon from "@mui/icons-material/Videocam";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import FolderZipIcon from "@mui/icons-material/FolderZip";
import GifBoxIcon from "@mui/icons-material/GifBox";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getAllUserAssets } from "../../store/main/actions";

function FilesList({ getAllUserAssets, userAssets }) {
  
  const getAssets = async () => {
    await getAllUserAssets();
  };
  
  useEffect(() => {
    getAssets();
    // eslint-disable-next-line
  }, []);

  return (
    <List sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
      {userAssets &&
        userAssets.map((asset, key) => {
          const inputDate = new Date(asset.updatedAt);
          const day = String(inputDate.getUTCDate()).padStart(2, "0");
          const month = String(inputDate.getUTCMonth() + 1).padStart(2, "0"); // Months are zero-based
          const year = inputDate.getUTCFullYear();
          const hours = String(inputDate.getUTCHours()).padStart(2, "0");
          const minutes = String(inputDate.getUTCMinutes()).padStart(2, "0");
          const seconds = String(inputDate.getUTCSeconds()).padStart(2, "0");
          const formattedDate = `${day}/${month}/${year} | ${hours}:${minutes}:${seconds}`;

          const bytes = asset.size;
          const megabytes = (bytes / (1024 * 1024)).toFixed(2);

          const parts = asset.public_id.split("/");
          const fileName = parts[parts.length - 1];
          const link = asset.link;
          const splittedLink = link.split(".");
          const fileType = splittedLink[splittedLink.length - 1];

          return (
            <Link
              key={key}
              to={link}
              style={{ color: "inherit", textDecoration: "inherit" }}
              target="_blank"
            >
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    {fileType === "jpg" && <ImageIcon />}
                    {fileType === "jpeg" && <ImageIcon />}
                    {fileType === "png" && <ImageIcon />}
                    {fileType === "pdf" && <PictureAsPdfIcon />}
                    {fileType === "gif" && <GifBoxIcon />}
                    {fileType === "mp3" && <MusicVideoIcon />}
                    {fileType === "mp4" && <VideocamIcon />}
                    {fileType === "zip" && <FolderZipIcon />}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={fileName}
                  secondary={`${formattedDate} | ${megabytes}MB`}
                />
              </ListItem>
            </Link>
          );
        })}
    </List>
  );
}

const mapStatetoProps = ({ main }) => ({
  userAssets: main.allAssets
});

export default connect(mapStatetoProps, { getAllUserAssets })(FilesList);
