import React, { useState } from "react";
import Button from "@mui/material/Button";
import UploadIcon from "@mui/icons-material/Upload";
import { Box, Typography } from "@mui/material";
import { uploadAsset } from "../../store/main/actions";
import { connect } from "react-redux";
const Upload = ({ uploadAsset }) => {
  const [reqFile, setFile] = useState();

  const changeHandler = async event => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    await uploadAsset(selectedFile);
  };

  return (
    <Box
      sx={{
        gap: "1rem",
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
      }}
    >
      <Button
        component="label"
        role={undefined}
        tabIndex={-1}
        variant="contained"
        size="large"
        startIcon={<UploadIcon />}
      >
        Upload a file
        <input
          type="file"
          name="file"
          onChange={changeHandler}
          style={{
            clip: "rect(0 0 0 0)",
            clipPath: "inset(50%)",
            height: "1px",
            overflow: "hidden",
            position: "absolute",
            bottom: "0",
            left: "0",
            whiteSpace: "nowrap",
            width: "1px"
          }}
        />
      </Button>
      {reqFile &&
        <Typography component="p" variant="p">
          {reqFile.name.length < 50 ? reqFile.name : reqFile.name.substr(0, 50)}
        </Typography>}
    </Box>
  );
};

export default connect(null, { uploadAsset })(Upload);
